<template>
  <div style="overflow: hidden">
    <div
      v-if="userWithSameEmail"
      class="h-screen w-full flex justify-center items-center"
    >
      <div
        style="width: 540px; height: 392px; padding-right: 3rem"
        class="relative bg-white pt-12 pl-12 border border-gray-200 shadow-sm"
      >
        <p class="text-3xl leading-9 font-semibold mb-4 text-gray-900">
          Looks like you have an existing account
        </p>
        <p class="text-gray-600 font-normal text-base">
          To link accounts, enter your current password in the field below to
          create a new organization account.
        </p>
        <input
          name="current_password"
          placeholder="Current password"
          type="password"
          v-model="form.existing_password"
          class="shadow-sm mt-4 mr-12 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
        <input
          class="btn button signup-submit"
          type="button"
          @click="submit"
          :value="submitButtonText"
          tabindex="4"
          :disabled="disableButton"
          data-disable-with="Log in"
        />
        <a
          href="/auth/password/new"
          class="mt-4 block text-base font-normal text-gray-600 underline"
        >
          Forgot your password?
        </a>
        <img
          id="cat-login"
          class="absolute"
          style="top: -45%; right: -15%; z-index: -1"
          :src="require(`images/reactivate_kitty.png`)"
        />
      </div>
    </div>
    <div :class="userWithSameEmail ? 'hidden' : ''" class="row my-0">
      <div class="col-md-6 col-sm-12">
        <div class="signup-main">
          <div class="signup-form-wrapper">
            <div class="form-container-signup smaller-form">
              <div class="subdomain-logo">
                <img
                  :src="require(`images/logo_one_line.png`)"
                  alt="Coach Catalyst"
                  class="signup-logo"
                />
              </div>
              <p class="signup-p" v-if="urlParam || superOrg">
                Create Your Account
              </p>
              <div v-else>
                <p class="signup-p">
                  Start Your {{ trialDays }}-Day
                  {{ trialAmount === 0 ? 'Free' : '$' + trialAmount }} Trial
                </p>
                <div style="text-align: left">
                  <label>Or</label
                  ><label class="login-start-trail" @click="signInClick">
                    sign in to your account</label
                  >
                </div>
              </div>
              <div>
                <form
                  novalidate="novalidate"
                  class="simple_form new_user mt-4"
                  id="new_user"
                  accept-charset="UTF-8"
                  method="post"
                >
                  <input name="utf8" type="hidden" value="✓" />
                  <fieldset>
                    <div v-if="!isLoginDetailsInParams" class="d-flex">
                      <div class="input optional">
                        <label class="signup-label">First Name</label>
                        <input
                          tabindex="1"
                          autofocus="autofocus"
                          class="string optional"
                          type="text"
                          v-model="form.first_name"
                          placeholder="John"
                        />
                      </div>

                      <div class="input place-last-name">
                        <label class="signup-label">Last Name</label>
                        <input
                          tabindex="2"
                          class="string optional"
                          type="text"
                          v-model="form.last_name"
                          placeholder="Doe"
                        />
                      </div>
                    </div>

                    <div
                      v-if="!isLoginDetailsInParams"
                      class="input email user_email"
                    >
                      <label class="signup-label">Email</label>
                      <input
                        tabindex="3"
                        autofocus="autofocus"
                        class="string email"
                        type="email"
                        v-model="form.email"
                        placeholder="john@example.com"
                      />
                    </div>
                    <div class="input user_password">
                      <label class="signup-label">Password</label>
                      <input
                        tabindex="4"
                        class="password optional"
                        type="password"
                        v-model="form.password"
                        placeholder="Password"
                      />
                    </div>
                    <div v-if="urlParam">
                      <label class="signup-label">Card Details</label>
                      <div class="plan-card">
                        <div ref="card"></div>
                      </div>
                    </div>
                    <div class="signup-policy">
                      <input
                        tabindex="7"
                        type="checkbox"
                        v-model="form.agree_terms"
                      />
                      <label class="signup-policy-label ml-1"
                        >By joining, you agree to the terms of service and
                        privacy policy</label
                      >
                    </div>

                    <div>
                      <input
                        class="btn button signup-submit"
                        type="button"
                        @click="submit"
                        :value="submitButtonText"
                        tabindex="4"
                        :disabled="disableButton"
                        data-disable-with="Log in"
                      />
                    </div>
                    <p
                      class="mt-3 text-sm text-gray-600"
                      v-if="urlParam === 'webinar75'"
                    >
                      After 3 months you will automatically be enrolled <br />
                      in the $99 / month (up to 75 clients) plan. <br />
                      you can cancel or downgrade your account at any time.
                    </p>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="right-container">
          <div v-if="!urlParam">
            <div class="trail-wrapper">
              <svg
                width="43"
                height="35"
                viewBox="0 0 45 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.028 0C5.684 5.184 0.5 13.68 0.5 23.04C0.5 30.672 5.108 35.136 10.436 35.136C15.476 35.136 19.22 31.104 19.22 26.352C19.22 21.6 15.908 18.144 11.588 18.144C10.724 18.144 9.572 18.288 9.284 18.432C10.004 13.536 14.612 7.776 19.22 4.896L13.028 0ZM37.796 0C30.596 5.184 25.412 13.68 25.412 23.04C25.412 30.672 30.02 35.136 35.348 35.136C40.244 35.136 44.132 31.104 44.132 26.352C44.132 21.6 40.676 18.144 36.356 18.144C35.492 18.144 34.484 18.288 34.196 18.432C34.916 13.536 39.38 7.776 43.988 4.896L37.796 0Z"
                  fill="white"
                  fill-opacity="0.25"
                />
              </svg>
              <p style="color: #ffffff">
                Coach Catalyst has the highest client<br />
                use I’ve ever seen. The UX is brilliantly<br />
                simple for the client, so they actually<br />
                use it! The coach side is incredible<br />
                simple yet robust in capibilities.<br /><br />
                10/10 recommend 💪
              </p>
              <p class="right-container-name">Erin Hurst</p>
              <p class="right-container-designation">Atomic Nutrition</p>
              <div class="image-div">
                <img
                  :src="require(`images/Rectangle.png`)"
                  style="height: 100%; width: 90%"
                />
              </div>
            </div>
          </div>
          <div v-if="urlParam === 'webinarUnlimited'">
            <div class="unlimited-plan-wrapper">
              <h3 class="plan-h3">
                Sign up today and get over $12,400 in value including...
              </h3>
              <ul class="plan-list">
                <li>
                  6 months of Coach Catalyst on the Unlimited Plan ($2988 Value)
                </li>
                <li>Inside the Brains Monthly Coaching Calls ($1500 Value)</li>
                <li>7 Day Kickstart Program ($149 Value)</li>
                <li>The MIND Diet Program ($397 Value)</li>
                <li>6-Month Habit Based Coaching Program ($2997 Value)</li>
                <li>Free Branded App for 6 Months ($419 Value)</li>
                <li>1-on-1 Business Strategy Call ($249 Value)</li>
                <li>The Nutrition Coaching Blueprint ($99 Vaule)</li>
              </ul>
            </div>
          </div>
          <div class="cc-plan-wrapper" v-if="urlParam === 'webinar75'">
            <h3 class="plan-h3">
              Sign up today and get over $7800 in value including...
            </h3>
            <ul class="plan-list">
              <li>3 Months of Coach Catalyst on the Pro Plan ($297 Value)</li>
              <li>Clean and Lean Transformation Challenge ($397 Value)</li>
              <li>7 Day Kickstart Program ($149 Value)</li>
              <li>The MIND Diet Program ($397 Value)</li>
              <li>6-Month Habit Based Coaching Program ($2997 Value)</li>
              <li>Transform in 4 Challenge ($297 Value)</li>
              <li>1-on-1 Business Strategy Call ($249 Value)</li>
              <li>The Nutrition Coaching Blueprint ($99 Vaule)</li>
            </ul>
          </div>
          <div v-if="urlParam">
            <div class="plan-bottom-div">
              <p class="plan-p">
                Coach Catalyst has the highest client use I’ve ever seen. The UX
                is<br />brilliantly simple for the client, so they actually use
                it! The coach side<br />is incredible simple yet robust in
                capabilities.
              </p>
              <p>10/10 recommend 💪</p>
              <div class="flex justify-center items-center mt-4">
                <img class="z-10 mr-2" :src="require(`images/avatar.png`)" />
                <p>
                  Erin Hurst<br /><span style="color: #e5e7eb !important"
                    >CEO, Atomic Nutrition</span
                  >
                </p>
              </div>
              <svg
                class="dotted-svg"
                width="247"
                height="55"
                viewBox="0 0 247 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M-157 0H-153V4H-157V0Z" fill="#065F46" />
                <path d="M-137 0H-133V4H-137V0Z" fill="#065F46" />
                <path d="M-117 0H-113V4H-117V0Z" fill="#065F46" />
                <path d="M-97 0H-93V4H-97V0Z" fill="#065F46" />
                <path d="M-77 0H-73V4H-77V0Z" fill="#065F46" />
                <path d="M-57 0H-53V4H-57V0Z" fill="#065F46" />
                <path d="M-37 0H-33V4H-37V0Z" fill="#065F46" />
                <path d="M-17 0H-13V4H-17V0Z" fill="#065F46" />
                <path d="M3 0H7V4H3V0Z" fill="#065F46" />
                <path d="M23 0H27V4H23V0Z" fill="#065F46" />
                <path d="M43 0H47V4H43V0Z" fill="#065F46" />
                <path d="M63 0H67V4H63V0Z" fill="#065F46" />
                <path d="M83 0H87V4H83V0Z" fill="#065F46" />
                <path d="M103 0H107V4H103V0Z" fill="#065F46" />
                <path d="M123 0H127V4H123V0Z" fill="#065F46" />
                <path d="M143 0H147V4H143V0Z" fill="#065F46" />
                <path d="M163 0H167V4H163V0Z" fill="#065F46" />
                <path d="M183 0H187V4H183V0Z" fill="#065F46" />
                <path d="M203 0H207V4H203V0Z" fill="#065F46" />
                <path d="M223 0H227V4H223V0Z" fill="#065F46" />
                <path d="M243 0H247V4H243V0Z" fill="#065F46" />
                <path d="M-157 20H-153V24H-157V20Z" fill="#065F46" />
                <path d="M-137 20H-133V24H-137V20Z" fill="#065F46" />
                <path d="M-117 20H-113V24H-117V20Z" fill="#065F46" />
                <path d="M-97 20H-93V24H-97V20Z" fill="#065F46" />
                <path d="M-77 20H-73V24H-77V20Z" fill="#065F46" />
                <path d="M-57 20H-53V24H-57V20Z" fill="#065F46" />
                <path d="M-37 20H-33V24H-37V20Z" fill="#065F46" />
                <path d="M-17 20H-13V24H-17V20Z" fill="#065F46" />
                <path d="M3 20H7V24H3V20Z" fill="#065F46" />
                <path d="M23 20H27V24H23V20Z" fill="#065F46" />
                <path d="M43 20H47V24H43V20Z" fill="#065F46" />
                <path d="M63 20H67V24H63V20Z" fill="#065F46" />
                <path d="M83 20H87V24H83V20Z" fill="#065F46" />
                <path d="M103 20H107V24H103V20Z" fill="#065F46" />
                <path d="M123 20H127V24H123V20Z" fill="#065F46" />
                <path d="M143 20H147V24H143V20Z" fill="#065F46" />
                <path d="M163 20H167V24H163V20Z" fill="#065F46" />
                <path d="M183 20H187V24H183V20Z" fill="#065F46" />
                <path d="M203 20H207V24H203V20Z" fill="#065F46" />
                <path d="M223 20H227V24H223V20Z" fill="#065F46" />
                <path d="M243 20H247V24H243V20Z" fill="#065F46" />
                <path d="M-157 40H-153V44H-157V40Z" fill="#065F46" />
                <path d="M-137 40H-133V44H-137V40Z" fill="#065F46" />
                <path d="M-117 40H-113V44H-117V40Z" fill="#065F46" />
                <path d="M-97 40H-93V44H-97V40Z" fill="#065F46" />
                <path d="M-77 40H-73V44H-77V40Z" fill="#065F46" />
                <path d="M-57 40H-53V44H-57V40Z" fill="#065F46" />
                <path d="M-37 40H-33V44H-37V40Z" fill="#065F46" />
                <path d="M-17 40H-13V44H-17V40Z" fill="#065F46" />
                <path d="M3 40H7V44H3V40Z" fill="#065F46" />
                <path d="M23 40H27V44H23V40Z" fill="#065F46" />
                <path d="M43 40H47V44H43V40Z" fill="#065F46" />
                <path d="M63 40H67V44H63V40Z" fill="#065F46" />
                <path d="M83 40H87V44H83V40Z" fill="#065F46" />
                <path d="M103 40H107V44H103V40Z" fill="#065F46" />
                <path d="M123 40H127V44H123V40Z" fill="#065F46" />
                <path d="M143 40H147V44H143V40Z" fill="#065F46" />
                <path d="M163 40H167V44H163V40Z" fill="#065F46" />
                <path d="M183 40H187V44H183V40Z" fill="#065F46" />
                <path d="M203 40H207V44H203V40Z" fill="#065F46" />
                <path d="M223 40H227V44H223V40Z" fill="#065F46" />
                <path d="M243 40H247V44H243V40Z" fill="#065F46" />
                <path d="M-157 60H-153V64H-157V60Z" fill="#065F46" />
                <path d="M-137 60H-133V64H-137V60Z" fill="#065F46" />
                <path d="M-117 60H-113V64H-117V60Z" fill="#065F46" />
                <path d="M-97 60H-93V64H-97V60Z" fill="#065F46" />
                <path d="M-77 60H-73V64H-77V60Z" fill="#065F46" />
                <path d="M-57 60H-53V64H-57V60Z" fill="#065F46" />
                <path d="M-37 60H-33V64H-37V60Z" fill="#065F46" />
                <path d="M-17 60H-13V64H-17V60Z" fill="#065F46" />
                <path d="M3 60H7V64H3V60Z" fill="#065F46" />
                <path d="M23 60H27V64H23V60Z" fill="#065F46" />
                <path d="M43 60H47V64H43V60Z" fill="#065F46" />
                <path d="M63 60H67V64H63V60Z" fill="#065F46" />
                <path d="M83 60H87V64H83V60Z" fill="#065F46" />
                <path d="M103 60H107V64H103V60Z" fill="#065F46" />
                <path d="M123 60H127V64H123V60Z" fill="#065F46" />
                <path d="M143 60H147V64H143V60Z" fill="#065F46" />
                <path d="M163 60H167V64H163V60Z" fill="#065F46" />
                <path d="M183 60H187V64H183V60Z" fill="#065F46" />
                <path d="M203 60H207V64H203V60Z" fill="#065F46" />
                <path d="M223 60H227V64H223V60Z" fill="#065F46" />
                <path d="M243 60H247V64H243V60Z" fill="#065F46" />
                <path d="M-157 80H-153V84H-157V80Z" fill="#065F46" />
                <path d="M-137 80H-133V84H-137V80Z" fill="#065F46" />
                <path d="M-117 80H-113V84H-117V80Z" fill="#065F46" />
                <path d="M-97 80H-93V84H-97V80Z" fill="#065F46" />
                <path d="M-77 80H-73V84H-77V80Z" fill="#065F46" />
                <path d="M-57 80H-53V84H-57V80Z" fill="#065F46" />
                <path d="M-37 80H-33V84H-37V80Z" fill="#065F46" />
                <path d="M-17 80H-13V84H-17V80Z" fill="#065F46" />
                <path d="M3 80H7V84H3V80Z" fill="#065F46" />
                <path d="M23 80H27V84H23V80Z" fill="#065F46" />
                <path d="M43 80H47V84H43V80Z" fill="#065F46" />
                <path d="M63 80H67V84H63V80Z" fill="#065F46" />
                <path d="M83 80H87V84H83V80Z" fill="#065F46" />
                <path d="M103 80H107V84H103V80Z" fill="#065F46" />
                <path d="M123 80H127V84H123V80Z" fill="#065F46" />
                <path d="M143 80H147V84H143V80Z" fill="#065F46" />
                <path d="M163 80H167V84H163V80Z" fill="#065F46" />
                <path d="M183 80H187V84H183V80Z" fill="#065F46" />
                <path d="M203 80H207V84H203V80Z" fill="#065F46" />
                <path d="M223 80H227V84H223V80Z" fill="#065F46" />
                <path d="M243 80H247V84H243V80Z" fill="#065F46" />
                <path d="M-157 100H-153V104H-157V100Z" fill="#065F46" />
                <path d="M-137 100H-133V104H-137V100Z" fill="#065F46" />
                <path d="M-117 100H-113V104H-117V100Z" fill="#065F46" />
                <path d="M-97 100H-93V104H-97V100Z" fill="#065F46" />
                <path d="M-77 100H-73V104H-77V100Z" fill="#065F46" />
                <path d="M-57 100H-53V104H-57V100Z" fill="#065F46" />
                <path d="M-37 100H-33V104H-37V100Z" fill="#065F46" />
                <path d="M-17 100H-13V104H-17V100Z" fill="#065F46" />
                <path d="M3 100H7V104H3V100Z" fill="#065F46" />
                <path d="M23 100H27V104H23V100Z" fill="#065F46" />
                <path d="M43 100H47V104H43V100Z" fill="#065F46" />
                <path d="M63 100H67V104H63V100Z" fill="#065F46" />
                <path d="M83 100H87V104H83V100Z" fill="#065F46" />
                <path d="M103 100H107V104H103V100Z" fill="#065F46" />
                <path d="M123 100H127V104H123V100Z" fill="#065F46" />
                <path d="M143 100H147V104H143V100Z" fill="#065F46" />
                <path d="M163 100H167V104H163V100Z" fill="#065F46" />
                <path d="M183 100H187V104H183V100Z" fill="#065F46" />
                <path d="M203 100H207V104H203V100Z" fill="#065F46" />
                <path d="M223 100H227V104H223V100Z" fill="#065F46" />
                <path d="M243 100H247V104H243V100Z" fill="#065F46" />
                <path d="M-157 120H-153V124H-157V120Z" fill="#065F46" />
                <path d="M-137 120H-133V124H-137V120Z" fill="#065F46" />
                <path d="M-117 120H-113V124H-117V120Z" fill="#065F46" />
                <path d="M-97 120H-93V124H-97V120Z" fill="#065F46" />
                <path d="M-77 120H-73V124H-77V120Z" fill="#065F46" />
                <path d="M-57 120H-53V124H-57V120Z" fill="#065F46" />
                <path d="M-37 120H-33V124H-37V120Z" fill="#065F46" />
                <path d="M-17 120H-13V124H-17V120Z" fill="#065F46" />
                <path d="M3 120H7V124H3V120Z" fill="#065F46" />
                <path d="M23 120H27V124H23V120Z" fill="#065F46" />
                <path d="M43 120H47V124H43V120Z" fill="#065F46" />
                <path d="M63 120H67V124H63V120Z" fill="#065F46" />
                <path d="M83 120H87V124H83V120Z" fill="#065F46" />
                <path d="M103 120H107V124H103V120Z" fill="#065F46" />
                <path d="M123 120H127V124H123V120Z" fill="#065F46" />
                <path d="M143 120H147V124H143V120Z" fill="#065F46" />
                <path d="M163 120H167V124H163V120Z" fill="#065F46" />
                <path d="M183 120H187V124H183V120Z" fill="#065F46" />
                <path d="M203 120H207V124H203V120Z" fill="#065F46" />
                <path d="M223 120H227V124H223V120Z" fill="#065F46" />
                <path d="M243 120H247V124H243V120Z" fill="#065F46" />
                <path d="M-157 140H-153V144H-157V140Z" fill="#065F46" />
                <path d="M-137 140H-133V144H-137V140Z" fill="#065F46" />
                <path d="M-117 140H-113V144H-117V140Z" fill="#065F46" />
                <path d="M-97 140H-93V144H-97V140Z" fill="#065F46" />
                <path d="M-77 140H-73V144H-77V140Z" fill="#065F46" />
                <path d="M-57 140H-53V144H-57V140Z" fill="#065F46" />
                <path d="M-37 140H-33V144H-37V140Z" fill="#065F46" />
                <path d="M-17 140H-13V144H-17V140Z" fill="#065F46" />
                <path d="M3 140H7V144H3V140Z" fill="#065F46" />
                <path d="M23 140H27V144H23V140Z" fill="#065F46" />
                <path d="M43 140H47V144H43V140Z" fill="#065F46" />
                <path d="M63 140H67V144H63V140Z" fill="#065F46" />
                <path d="M83 140H87V144H83V140Z" fill="#065F46" />
                <path d="M103 140H107V144H103V140Z" fill="#065F46" />
                <path d="M123 140H127V144H123V140Z" fill="#065F46" />
                <path d="M143 140H147V144H143V140Z" fill="#065F46" />
                <path d="M163 140H167V144H163V140Z" fill="#065F46" />
                <path d="M183 140H187V144H183V140Z" fill="#065F46" />
                <path d="M203 140H207V144H203V140Z" fill="#065F46" />
                <path d="M223 140H227V144H223V140Z" fill="#065F46" />
                <path d="M243 140H247V144H243V140Z" fill="#065F46" />
                <path d="M-157 160H-153V164H-157V160Z" fill="#065F46" />
                <path d="M-137 160H-133V164H-137V160Z" fill="#065F46" />
                <path d="M-117 160H-113V164H-117V160Z" fill="#065F46" />
                <path d="M-97 160H-93V164H-97V160Z" fill="#065F46" />
                <path d="M-77 160H-73V164H-77V160Z" fill="#065F46" />
                <path d="M-57 160H-53V164H-57V160Z" fill="#065F46" />
                <path d="M-37 160H-33V164H-37V160Z" fill="#065F46" />
                <path d="M-17 160H-13V164H-17V160Z" fill="#065F46" />
                <path d="M3 160H7V164H3V160Z" fill="#065F46" />
                <path d="M23 160H27V164H23V160Z" fill="#065F46" />
                <path d="M43 160H47V164H43V160Z" fill="#065F46" />
                <path d="M63 160H67V164H63V160Z" fill="#065F46" />
                <path d="M83 160H87V164H83V160Z" fill="#065F46" />
                <path d="M103 160H107V164H103V160Z" fill="#065F46" />
                <path d="M123 160H127V164H123V160Z" fill="#065F46" />
                <path d="M143 160H147V164H143V160Z" fill="#065F46" />
                <path d="M163 160H167V164H163V160Z" fill="#065F46" />
                <path d="M183 160H187V164H183V160Z" fill="#065F46" />
                <path d="M203 160H207V164H203V160Z" fill="#065F46" />
                <path d="M223 160H227V164H223V160Z" fill="#065F46" />
                <path d="M243 160H247V164H243V160Z" fill="#065F46" />
                <path d="M-157 180H-153V184H-157V180Z" fill="#065F46" />
                <path d="M-137 180H-133V184H-137V180Z" fill="#065F46" />
                <path d="M-117 180H-113V184H-117V180Z" fill="#065F46" />
                <path d="M-97 180H-93V184H-97V180Z" fill="#065F46" />
                <path d="M-77 180H-73V184H-77V180Z" fill="#065F46" />
                <path d="M-57 180H-53V184H-57V180Z" fill="#065F46" />
                <path d="M-37 180H-33V184H-37V180Z" fill="#065F46" />
                <path d="M-17 180H-13V184H-17V180Z" fill="#065F46" />
                <path d="M3 180H7V184H3V180Z" fill="#065F46" />
                <path d="M23 180H27V184H23V180Z" fill="#065F46" />
                <path d="M43 180H47V184H43V180Z" fill="#065F46" />
                <path d="M63 180H67V184H63V180Z" fill="#065F46" />
                <path d="M83 180H87V184H83V180Z" fill="#065F46" />
                <path d="M103 180H107V184H103V180Z" fill="#065F46" />
                <path d="M123 180H127V184H123V180Z" fill="#065F46" />
                <path d="M143 180H147V184H143V180Z" fill="#065F46" />
                <path d="M163 180H167V184H163V180Z" fill="#065F46" />
                <path d="M183 180H187V184H183V180Z" fill="#065F46" />
                <path d="M203 180H207V184H203V180Z" fill="#065F46" />
                <path d="M223 180H227V184H223V180Z" fill="#065F46" />
                <path d="M243 180H247V184H243V180Z" fill="#065F46" />
                <path d="M-157 200H-153V204H-157V200Z" fill="#065F46" />
                <path d="M-137 200H-133V204H-137V200Z" fill="#065F46" />
                <path d="M-117 200H-113V204H-117V200Z" fill="#065F46" />
                <path d="M-97 200H-93V204H-97V200Z" fill="#065F46" />
                <path d="M-77 200H-73V204H-77V200Z" fill="#065F46" />
                <path d="M-57 200H-53V204H-57V200Z" fill="#065F46" />
                <path d="M-37 200H-33V204H-37V200Z" fill="#065F46" />
                <path d="M-17 200H-13V204H-17V200Z" fill="#065F46" />
                <path d="M3 200H7V204H3V200Z" fill="#065F46" />
                <path d="M23 200H27V204H23V200Z" fill="#065F46" />
                <path d="M43 200H47V204H43V200Z" fill="#065F46" />
                <path d="M63 200H67V204H63V200Z" fill="#065F46" />
                <path d="M83 200H87V204H83V200Z" fill="#065F46" />
                <path d="M103 200H107V204H103V200Z" fill="#065F46" />
                <path d="M123 200H127V204H123V200Z" fill="#065F46" />
                <path d="M143 200H147V204H143V200Z" fill="#065F46" />
                <path d="M163 200H167V204H163V200Z" fill="#065F46" />
                <path d="M183 200H187V204H183V200Z" fill="#065F46" />
                <path d="M203 200H207V204H203V200Z" fill="#065F46" />
                <path d="M223 200H227V204H223V200Z" fill="#065F46" />
                <path d="M243 200H247V204H243V200Z" fill="#065F46" />
                <path d="M-157 220H-153V224H-157V220Z" fill="#065F46" />
                <path d="M-137 220H-133V224H-137V220Z" fill="#065F46" />
                <path d="M-117 220H-113V224H-117V220Z" fill="#065F46" />
                <path d="M-97 220H-93V224H-97V220Z" fill="#065F46" />
                <path d="M-77 220H-73V224H-77V220Z" fill="#065F46" />
                <path d="M-57 220H-53V224H-57V220Z" fill="#065F46" />
                <path d="M-37 220H-33V224H-37V220Z" fill="#065F46" />
                <path d="M-17 220H-13V224H-17V220Z" fill="#065F46" />
                <path d="M3 220H7V224H3V220Z" fill="#065F46" />
                <path d="M23 220H27V224H23V220Z" fill="#065F46" />
                <path d="M43 220H47V224H43V220Z" fill="#065F46" />
                <path d="M63 220H67V224H63V220Z" fill="#065F46" />
                <path d="M83 220H87V224H83V220Z" fill="#065F46" />
                <path d="M103 220H107V224H103V220Z" fill="#065F46" />
                <path d="M123 220H127V224H123V220Z" fill="#065F46" />
                <path d="M143 220H147V224H143V220Z" fill="#065F46" />
                <path d="M163 220H167V224H163V220Z" fill="#065F46" />
                <path d="M183 220H187V224H183V220Z" fill="#065F46" />
                <path d="M203 220H207V224H203V220Z" fill="#065F46" />
                <path d="M223 220H227V224H223V220Z" fill="#065F46" />
                <path d="M243 220H247V224H243V220Z" fill="#065F46" />
                <path d="M-157 240H-153V244H-157V240Z" fill="#065F46" />
                <path d="M-137 240H-133V244H-137V240Z" fill="#065F46" />
                <path d="M-117 240H-113V244H-117V240Z" fill="#065F46" />
                <path d="M-97 240H-93V244H-97V240Z" fill="#065F46" />
                <path d="M-77 240H-73V244H-77V240Z" fill="#065F46" />
                <path d="M-57 240H-53V244H-57V240Z" fill="#065F46" />
                <path d="M-37 240H-33V244H-37V240Z" fill="#065F46" />
                <path d="M-17 240H-13V244H-17V240Z" fill="#065F46" />
                <path d="M3 240H7V244H3V240Z" fill="#065F46" />
                <path d="M23 240H27V244H23V240Z" fill="#065F46" />
                <path d="M43 240H47V244H43V240Z" fill="#065F46" />
                <path d="M63 240H67V244H63V240Z" fill="#065F46" />
                <path d="M83 240H87V244H83V240Z" fill="#065F46" />
                <path d="M103 240H107V244H103V240Z" fill="#065F46" />
                <path d="M123 240H127V244H123V240Z" fill="#065F46" />
                <path d="M143 240H147V244H143V240Z" fill="#065F46" />
                <path d="M163 240H167V244H163V240Z" fill="#065F46" />
                <path d="M183 240H187V244H183V240Z" fill="#065F46" />
                <path d="M203 240H207V244H203V240Z" fill="#065F46" />
                <path d="M223 240H227V244H223V240Z" fill="#065F46" />
                <path d="M243 240H247V244H243V240Z" fill="#065F46" />
                <path d="M-157 260H-153V264H-157V260Z" fill="#065F46" />
                <path d="M-137 260H-133V264H-137V260Z" fill="#065F46" />
                <path d="M-117 260H-113V264H-117V260Z" fill="#065F46" />
                <path d="M-97 260H-93V264H-97V260Z" fill="#065F46" />
                <path d="M-77 260H-73V264H-77V260Z" fill="#065F46" />
                <path d="M-57 260H-53V264H-57V260Z" fill="#065F46" />
                <path d="M-37 260H-33V264H-37V260Z" fill="#065F46" />
                <path d="M-17 260H-13V264H-17V260Z" fill="#065F46" />
                <path d="M3 260H7V264H3V260Z" fill="#065F46" />
                <path d="M23 260H27V264H23V260Z" fill="#065F46" />
                <path d="M43 260H47V264H43V260Z" fill="#065F46" />
                <path d="M63 260H67V264H63V260Z" fill="#065F46" />
                <path d="M83 260H87V264H83V260Z" fill="#065F46" />
                <path d="M103 260H107V264H103V260Z" fill="#065F46" />
                <path d="M123 260H127V264H123V260Z" fill="#065F46" />
                <path d="M143 260H147V264H143V260Z" fill="#065F46" />
                <path d="M163 260H167V264H163V260Z" fill="#065F46" />
                <path d="M183 260H187V264H183V260Z" fill="#065F46" />
                <path d="M203 260H207V264H203V260Z" fill="#065F46" />
                <path d="M223 260H227V264H223V260Z" fill="#065F46" />
                <path d="M243 260H247V264H243V260Z" fill="#065F46" />
                <path d="M-157 280H-153V284H-157V280Z" fill="#065F46" />
                <path d="M-137 280H-133V284H-137V280Z" fill="#065F46" />
                <path d="M-117 280H-113V284H-117V280Z" fill="#065F46" />
                <path d="M-97 280H-93V284H-97V280Z" fill="#065F46" />
                <path d="M-77 280H-73V284H-77V280Z" fill="#065F46" />
                <path d="M-57 280H-53V284H-57V280Z" fill="#065F46" />
                <path d="M-37 280H-33V284H-37V280Z" fill="#065F46" />
                <path d="M-17 280H-13V284H-17V280Z" fill="#065F46" />
                <path d="M3 280H7V284H3V280Z" fill="#065F46" />
                <path d="M23 280H27V284H23V280Z" fill="#065F46" />
                <path d="M43 280H47V284H43V280Z" fill="#065F46" />
                <path d="M63 280H67V284H63V280Z" fill="#065F46" />
                <path d="M83 280H87V284H83V280Z" fill="#065F46" />
                <path d="M103 280H107V284H103V280Z" fill="#065F46" />
                <path d="M123 280H127V284H123V280Z" fill="#065F46" />
                <path d="M143 280H147V284H143V280Z" fill="#065F46" />
                <path d="M163 280H167V284H163V280Z" fill="#065F46" />
                <path d="M183 280H187V284H183V280Z" fill="#065F46" />
                <path d="M203 280H207V284H203V280Z" fill="#065F46" />
                <path d="M223 280H227V284H223V280Z" fill="#065F46" />
                <path d="M243 280H247V284H243V280Z" fill="#065F46" />
                <path d="M-157 300H-153V304H-157V300Z" fill="#065F46" />
                <path d="M-137 300H-133V304H-137V300Z" fill="#065F46" />
                <path d="M-117 300H-113V304H-117V300Z" fill="#065F46" />
                <path d="M-97 300H-93V304H-97V300Z" fill="#065F46" />
                <path d="M-77 300H-73V304H-77V300Z" fill="#065F46" />
                <path d="M-57 300H-53V304H-57V300Z" fill="#065F46" />
                <path d="M-37 300H-33V304H-37V300Z" fill="#065F46" />
                <path d="M-17 300H-13V304H-17V300Z" fill="#065F46" />
                <path d="M3 300H7V304H3V300Z" fill="#065F46" />
                <path d="M23 300H27V304H23V300Z" fill="#065F46" />
                <path d="M43 300H47V304H43V300Z" fill="#065F46" />
                <path d="M63 300H67V304H63V300Z" fill="#065F46" />
                <path d="M83 300H87V304H83V300Z" fill="#065F46" />
                <path d="M103 300H107V304H103V300Z" fill="#065F46" />
                <path d="M123 300H127V304H123V300Z" fill="#065F46" />
                <path d="M143 300H147V304H143V300Z" fill="#065F46" />
                <path d="M163 300H167V304H163V300Z" fill="#065F46" />
                <path d="M183 300H187V304H183V300Z" fill="#065F46" />
                <path d="M203 300H207V304H203V300Z" fill="#065F46" />
                <path d="M223 300H227V304H223V300Z" fill="#065F46" />
                <path d="M243 300H247V304H243V300Z" fill="#065F46" />
                <path d="M-157 320H-153V324H-157V320Z" fill="#065F46" />
                <path d="M-137 320H-133V324H-137V320Z" fill="#065F46" />
                <path d="M-117 320H-113V324H-117V320Z" fill="#065F46" />
                <path d="M-97 320H-93V324H-97V320Z" fill="#065F46" />
                <path d="M-77 320H-73V324H-77V320Z" fill="#065F46" />
                <path d="M-57 320H-53V324H-57V320Z" fill="#065F46" />
                <path d="M-37 320H-33V324H-37V320Z" fill="#065F46" />
                <path d="M-17 320H-13V324H-17V320Z" fill="#065F46" />
                <path d="M3 320H7V324H3V320Z" fill="#065F46" />
                <path d="M23 320H27V324H23V320Z" fill="#065F46" />
                <path d="M43 320H47V324H43V320Z" fill="#065F46" />
                <path d="M63 320H67V324H63V320Z" fill="#065F46" />
                <path d="M83 320H87V324H83V320Z" fill="#065F46" />
                <path d="M103 320H107V324H103V320Z" fill="#065F46" />
                <path d="M123 320H127V324H123V320Z" fill="#065F46" />
                <path d="M143 320H147V324H143V320Z" fill="#065F46" />
                <path d="M163 320H167V324H163V320Z" fill="#065F46" />
                <path d="M183 320H187V324H183V320Z" fill="#065F46" />
                <path d="M203 320H207V324H203V320Z" fill="#065F46" />
                <path d="M223 320H227V324H223V320Z" fill="#065F46" />
                <path d="M243 320H247V324H243V320Z" fill="#065F46" />
                <path d="M-157 340H-153V344H-157V340Z" fill="#065F46" />
                <path d="M-137 340H-133V344H-137V340Z" fill="#065F46" />
                <path d="M-117 340H-113V344H-117V340Z" fill="#065F46" />
                <path d="M-97 340H-93V344H-97V340Z" fill="#065F46" />
                <path d="M-77 340H-73V344H-77V340Z" fill="#065F46" />
                <path d="M-57 340H-53V344H-57V340Z" fill="#065F46" />
                <path d="M-37 340H-33V344H-37V340Z" fill="#065F46" />
                <path d="M-17 340H-13V344H-17V340Z" fill="#065F46" />
                <path d="M3 340H7V344H3V340Z" fill="#065F46" />
                <path d="M23 340H27V344H23V340Z" fill="#065F46" />
                <path d="M43 340H47V344H43V340Z" fill="#065F46" />
                <path d="M63 340H67V344H63V340Z" fill="#065F46" />
                <path d="M83 340H87V344H83V340Z" fill="#065F46" />
                <path d="M103 340H107V344H103V340Z" fill="#065F46" />
                <path d="M123 340H127V344H123V340Z" fill="#065F46" />
                <path d="M143 340H147V344H143V340Z" fill="#065F46" />
                <path d="M163 340H167V344H163V340Z" fill="#065F46" />
                <path d="M183 340H187V344H183V340Z" fill="#065F46" />
                <path d="M203 340H207V344H203V340Z" fill="#065F46" />
                <path d="M223 340H227V344H223V340Z" fill="#065F46" />
                <path d="M243 340H247V344H243V340Z" fill="#065F46" />
                <path d="M-157 360H-153V364H-157V360Z" fill="#065F46" />
                <path d="M-137 360H-133V364H-137V360Z" fill="#065F46" />
                <path d="M-117 360H-113V364H-117V360Z" fill="#065F46" />
                <path d="M-97 360H-93V364H-97V360Z" fill="#065F46" />
                <path d="M-77 360H-73V364H-77V360Z" fill="#065F46" />
                <path d="M-57 360H-53V364H-57V360Z" fill="#065F46" />
                <path d="M-37 360H-33V364H-37V360Z" fill="#065F46" />
                <path d="M-17 360H-13V364H-17V360Z" fill="#065F46" />
                <path d="M3 360H7V364H3V360Z" fill="#065F46" />
                <path d="M23 360H27V364H23V360Z" fill="#065F46" />
                <path d="M43 360H47V364H43V360Z" fill="#065F46" />
                <path d="M63 360H67V364H63V360Z" fill="#065F46" />
                <path d="M83 360H87V364H83V360Z" fill="#065F46" />
                <path d="M103 360H107V364H103V360Z" fill="#065F46" />
                <path d="M123 360H127V364H123V360Z" fill="#065F46" />
                <path d="M143 360H147V364H143V360Z" fill="#065F46" />
                <path d="M163 360H167V364H163V360Z" fill="#065F46" />
                <path d="M183 360H187V364H183V360Z" fill="#065F46" />
                <path d="M203 360H207V364H203V360Z" fill="#065F46" />
                <path d="M223 360H227V364H223V360Z" fill="#065F46" />
                <path d="M243 360H247V364H243V360Z" fill="#065F46" />
                <path d="M-157 380H-153V384H-157V380Z" fill="#065F46" />
                <path d="M-137 380H-133V384H-137V380Z" fill="#065F46" />
                <path d="M-117 380H-113V384H-117V380Z" fill="#065F46" />
                <path d="M-97 380H-93V384H-97V380Z" fill="#065F46" />
                <path d="M-77 380H-73V384H-77V380Z" fill="#065F46" />
                <path d="M-57 380H-53V384H-57V380Z" fill="#065F46" />
                <path d="M-37 380H-33V384H-37V380Z" fill="#065F46" />
                <path d="M-17 380H-13V384H-17V380Z" fill="#065F46" />
                <path d="M3 380H7V384H3V380Z" fill="#065F46" />
                <path d="M23 380H27V384H23V380Z" fill="#065F46" />
                <path d="M43 380H47V384H43V380Z" fill="#065F46" />
                <path d="M63 380H67V384H63V380Z" fill="#065F46" />
                <path d="M83 380H87V384H83V380Z" fill="#065F46" />
                <path d="M103 380H107V384H103V380Z" fill="#065F46" />
                <path d="M123 380H127V384H123V380Z" fill="#065F46" />
                <path d="M143 380H147V384H143V380Z" fill="#065F46" />
                <path d="M163 380H167V384H163V380Z" fill="#065F46" />
                <path d="M183 380H187V384H183V380Z" fill="#065F46" />
                <path d="M203 380H207V384H203V380Z" fill="#065F46" />
                <path d="M223 380H227V384H223V380Z" fill="#065F46" />
                <path d="M243 380H247V384H243V380Z" fill="#065F46" />
                <path d="M-157 400H-153V404H-157V400Z" fill="#065F46" />
                <path d="M-137 400H-133V404H-137V400Z" fill="#065F46" />
                <path d="M-117 400H-113V404H-117V400Z" fill="#065F46" />
                <path d="M-97 400H-93V404H-97V400Z" fill="#065F46" />
                <path d="M-77 400H-73V404H-77V400Z" fill="#065F46" />
                <path d="M-57 400H-53V404H-57V400Z" fill="#065F46" />
                <path d="M-37 400H-33V404H-37V400Z" fill="#065F46" />
                <path d="M-17 400H-13V404H-17V400Z" fill="#065F46" />
                <path d="M3 400H7V404H3V400Z" fill="#065F46" />
                <path d="M23 400H27V404H23V400Z" fill="#065F46" />
                <path d="M43 400H47V404H43V400Z" fill="#065F46" />
                <path d="M63 400H67V404H63V400Z" fill="#065F46" />
                <path d="M83 400H87V404H83V400Z" fill="#065F46" />
                <path d="M103 400H107V404H103V400Z" fill="#065F46" />
                <path d="M123 400H127V404H123V400Z" fill="#065F46" />
                <path d="M143 400H147V404H143V400Z" fill="#065F46" />
                <path d="M163 400H167V404H163V400Z" fill="#065F46" />
                <path d="M183 400H187V404H183V400Z" fill="#065F46" />
                <path d="M203 400H207V404H203V400Z" fill="#065F46" />
                <path d="M223 400H227V404H223V400Z" fill="#065F46" />
                <path d="M243 400H247V404H243V400Z" fill="#065F46" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import ApiUrls from '../../api-url';
  import AuthenticationMixin from '../../authentication-mixin';
  import ValidationMixin from '../../validation-mixin';
  import { mapGetters, mapState } from 'vuex';
  import getters from '../../stores/login-store/getters/login-getters';

  let style = {
    base: {
      border: '1px solid #D8D8D8',
      borderRadius: '4px',
      color: '#000',
    },
    invalid: {
      // All of the error styles go inside of here.
    },
  };
  let stripe = Stripe(Vue.prototype.$stripe_publish_key, style),
    elements = stripe.elements(),
    card = undefined;
  export default {
    mixins: [ApiUrls, AuthenticationMixin, ValidationMixin],
    components: {},
    data() {
      return {
        form: {
          first_name: null,
          last_name: null,
          email: null,
          password: null,
          agree_terms: false,
          existing_password: null,
          organization: {
            referral: '',
            referral_text: null,
            linkmink: null,
          },
        },
        disableButton: false,
        userWithSameEmail: false,
        stripeToken: null,
        isLoginDetailsInParams: false,
      };
    },
    async created() {
      await this.$store.commit('setUrlParam', this.$route.query.url_param);
      await this.$store.commit('setAccountType', this.$route.query.accountType);
      await this.$store.commit('setSuperOrg', this.$route.query.superOrg);
      await this.$store.commit('setFbClickId', this.$route.query.fbClickId);
      await this.$store.commit('setCopyCode', this.$route.query.copyCode);
      await this.getPlan();
      try {
        const linkmink = LinkMink('pub_live_Y2uAe3gyQJBmmYcpRBxz');
        const lm_data = linkmink.getReferralData();
        if (lm_data) {
          this.form.organization.linkmink = lm_data;
        }
      } catch (err) {
        console.log(err);
      }
      if (!this.isEmptyObject(this.$route.query)) {
        this.form.first_name = this.$route.query?.firstname || '';
        this.form.last_name = this.$route.query?.lastname || '';
        this.form.email = this.$route.query?.email || '';
        if (
          this.$route.query?.email &&
          this.$route.query?.firstname &&
          this.$route.query?.email
        ) {
          this.isLoginDetailsInParams = true;
        }
      }
    },
    mounted: function () {
      if (this.urlParam) {
        this.$nextTick(() => {
          card = elements.create('card');
          card.mount(this.$refs.card);
          this.$nextTick(() => {
            card.on('change', (event) => {
              if (event.complete) {
                this.disableButton = !event.complete;
              } else if (event.empty || event.error) {
                this.disableButton = true;
              }
            });
            this.validateCard();
          });
        });
      }
    },
    beforeUnmount() {
      if (this.urlParam) card.destroy(this.$refs.card);
    },
    computed: {
      ...mapState([
        'trialAmount',
        'trialDays',
        'urlParam',
        'currentPlan',
        'accountType',
        'superOrg',
        'fbClickId',
        'copyCode',
      ]),
      ...mapGetters(['LOGIN_FORM', 'SIGN_UP_PERSONAL_INFO']),
      submitButtonText() {
        return this.urlParam
          ? `Get Started Today for Just $${
              parseInt(this.currentPlan.amount) / 100
            }`
          : 'Get Started';
      },
    },
    methods: {
      validateCard() {
        card.on('ready', () => {
          this.disableButton = true;
        });
        card.on('focus', () => {
          this.form.agree_terms = false;
        });
      },
      signInClick() {
        this.$router.push({ name: 'SigninPage' });
      },
      async getPlan() {
        let url_param = this.urlParam;
        const response = await axios.post(this.authUrl + 'get_plan', {
          url_param,
        });
        this.$store.commit('setCurrentPlan', response.data.result);
      },
      isValid() {
        if (!this.form.first_name) {
          this.$toast.show('First name required.', this.toasted_options.error);
          return false;
        }

        if (!this.form.last_name) {
          this.$toast.show('Last name required.', this.toasted_options.error);
          return false;
        }

        if (!this.validEmail(this.form.email)) {
          this.$toast.show('Invalid email.', this.toasted_options.error);
          return false;
        }

        if (!this.validPassword(this.form.password)) {
          this.$toast.show(
            'Password must be at least 6 characters.',
            this.toasted_options.error
          );
          return false;
        }
        if (!this.form.agree_terms) {
          this.$toast.show(
            'Please agree to the terms of service.',
            this.toasted_options.error
          );
          return false;
        }
        return true;
      },
      async submit() {
        if (this.isValid()) {
          this.disableButton = true;
          const self = this;
          grecaptcha.ready(function () {
            grecaptcha
              .execute('6LfiNc4ZAAAAAA7O27dikuMBReTDKH_QeP6sLmxn', {
                action: 'submit',
              })
              .then(async (token) => {
                window.fpr('referral', { email: self.form.email });
                self.$emit('save-user', self.form);

                const result = await axios.post('/auth', {
                  user: self.form,
                  token: token,
                  accountType: self.accountType,
                  superOrg: self.superOrg,
                  fbClickId: self.fbClickId,
                  copyCode: self.copyCode,
                  existing_password: self.existing_password,
                });

                console.log(result.data);
                if (result.data.success) {
                  self.userWithSameEmail = false;
                  if (self.urlParam) {
                    document.body.style.cursor = 'wait';
                    stripe.createToken(card).then(async (result) => {
                      await self.submitResponse(result.token);
                    });
                  } else {
                    self.$store.commit(
                      'setSelectedStep',
                      getters.SIGN_UP_PERSONAL_INFO()
                    );
                  }
                } else {
                  self.disableButton = false;
                  if (
                    result.data.error.indexOf(
                      'Please select a different email.'
                    ) > -1
                  ) {
                    self.userWithSameEmail = true;
                  } else {
                    self.$toast.show(
                      result.data.error.replace('uncaught throw', ''),
                      self.toasted_options.error
                    );
                  }
                }
              });
          });
        } else {
          this.$store.commit(
            'setSelectedStep',
            getters.SIGN_UP_CREATE_ACCOUNT()
          );
        }
      },
      async submitResponse(token) {
        let response = await axios.post(
          this.billingUrl + 'update_billing_plan',
          {
            plan: this.currentPlan,
            token: token,
            url_param: this.urlParam,
            background: true,
          }
        );
        document.body.style.cursor = 'default';
        if (response.data.success) {
          this.$store.commit(
            'setSelectedStep',
            getters.SIGN_UP_PERSONAL_INFO()
          );
        } else {
          this.$toast.show(response.data.result, this.toasted_options.error);
          this.disableButton = false;
        }
      },
      isEmptyObject(obj) {
        return JSON.stringify(obj) === '{}';
      },
    },
  };
</script>
